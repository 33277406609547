import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { StorageService } from 'src/app/common/services/storage.service';
import { IAppState } from 'src/app/store/states/app.state';

import { FONTS } from './common/constants/fonts';
import { WORKSHEETS_VARIANT } from './common/constants/worksheets-variant';
import { AnalyticTypesEnum } from './common/enums/analytic-types.enum';
import { AnalyticService } from './common/services/analytic.service';
import { AuthService } from './common/services/auth.service';
import { SubscriptionService } from './common/services/subscription.service';
import { GetUserInfoEmail, GetUserInfoUpgrade } from './store/actions/user-info.actions';
import { GetSpecialVariantFromServer, GetVariantByBrowserId, GetVariantByType, UpdateAndChangeVariant } from './store/actions/variant.actions';

@Injectable()
export class AppService {

  constructor(
    private readonly storageService: StorageService,
    private readonly store: Store<IAppState>,
    private readonly meta: Meta,
    private readonly authService: AuthService,
    private readonly subscriptionService: SubscriptionService,
    private readonly analyticService: AnalyticService,
  ) {}

  public setVariant(): void {
    const origin = window.location.origin;
    const storageVariant = this.storageService.getVariant();
    if (storageVariant) {
      this.store.dispatch(new UpdateAndChangeVariant(storageVariant));
      return;
    }
    if (origin.indexOf('welcome.intellectokids.com') !== -1) {
      this.store.dispatch(new GetSpecialVariantFromServer('3d47452c-1fd9-4931-8170-6f42634e88b7'));
      return;
    }
    const variantId = new URLSearchParams(window.location.search).get('variant_id');
    if (variantId) {
      this.store.dispatch(new GetSpecialVariantFromServer(variantId));
      return;
    }
    const pathname = window.location.pathname;
    switch (true) {

      case pathname.indexOf('/basic') !== -1: {
        this.store.dispatch(new GetSpecialVariantFromServer('3d47452c-1fd9-4931-8170-6f42634e88b7'));
        break;
      }

      case pathname.indexOf('/worksheets') !== -1: {
        this.store.dispatch(new UpdateAndChangeVariant(WORKSHEETS_VARIANT));
        break;
      }

      case pathname.indexOf('/checkout') !== -1 : {
        this.store.dispatch(new GetSpecialVariantFromServer('cb0eb3d9-ab49-4d69-8d34-0afd10115b7f'));
        break;
      }

      case pathname.indexOf('/login') !== -1 : {
        this.store.dispatch(new GetSpecialVariantFromServer('cb0eb3d9-ab49-4d69-8d34-0afd10115b7f'));
        break;
      }

      case pathname.indexOf('/75_new_users_v2') !== -1 : {
        this.store.dispatch(new GetSpecialVariantFromServer('05d6f64f-3c29-4a3e-a1fb-55f5c3de366d'));
        break;
      }

      case pathname.indexOf('/75_new_users') !== -1 : {
        this.store.dispatch(new GetSpecialVariantFromServer('9d20348c-babc-4362-9ec0-c2a89f8e0589'));
        break;
      }

      case pathname.indexOf('/year_discount') !== -1 : {
        this.store.dispatch(new GetSpecialVariantFromServer('c8ca3bc1-64a4-4233-9a6f-9575d0d6b1b9'));
        break;
      }

      case pathname.indexOf('/learning-games-6-months') !== -1 : {
        this.store.dispatch(new GetSpecialVariantFromServer('e0230f60-229e-41ce-8c3a-9002bc82330a'));
        break;
      }

      case pathname.indexOf('/lg-6-months') !== -1 : {
        this.store.dispatch(new GetSpecialVariantFromServer('0f32fa15-d996-4e8b-a556-690d2bf27e4b'));
        break;
      }

      case pathname.indexOf('/ftc') !== -1 : {
        this.store.dispatch(new GetSpecialVariantFromServer('b110765c-3331-476d-a24c-1329b87af0d5'));
        break;
      }

      case pathname.indexOf('/internal_test_link') !== -1 : {
        this.store.dispatch(new GetSpecialVariantFromServer('18a587c7-c99a-4958-83b4-3c65a9455671'));
        break;
      }

      case pathname.indexOf('/pm') !== -1 : {
        this.store.dispatch(new GetSpecialVariantFromServer('d0ffc2f3-9d99-4481-9ce9-b129fda06ae5'));
        break;
      }

      case pathname.indexOf('/new_product') !== -1 : {
        this.store.dispatch(new GetVariantByType(this.storageService.getUserId(), 'new_product'));
        break;
      }

      case pathname.indexOf('/inst') !== -1 : {
        this.store.dispatch(new GetVariantByType(this.storageService.getUserId(), 'inst'));
        break;
      }

      case pathname.indexOf('/fb-kids-education') !== -1 : {
        this.store.dispatch(new GetVariantByType(this.storageService.getUserId(), 'fb-kids-education'));
        break;
      }

      case pathname.indexOf('/personalized_program_v1') !== -1 : {
        this.store.dispatch(new GetSpecialVariantFromServer('fdf211fc-9f40-4867-a2dd-966633c4c8eb'));
        break;
      }

      case pathname.indexOf('/program_1_local') !== -1 : {
        this.store.dispatch(new GetVariantByType(this.storageService.getUserId(), 'program_1_local'));
        break;
      }

      case pathname.indexOf('/renewal') !== -1 : {
        this.store.dispatch(new GetSpecialVariantFromServer('44df5442-0841-4668-97c1-30a9d824007e'));
        break;
      }

      case pathname.indexOf('/hey_halloween') !== -1 : {
        this.store.dispatch(new GetSpecialVariantFromServer('457694c1-1d10-40cf-8b4d-79cc325b0da4'));
        break;
      }

      case pathname.indexOf('/halloween') !== -1 : {
        this.store.dispatch(new GetSpecialVariantFromServer('485e8fe2-3a1a-43d0-af98-f7540718a108'));
        break;
      }

      case pathname.indexOf('/hey_blackfriday') !== -1 : {
        this.store.dispatch(new GetSpecialVariantFromServer('31602f21-1514-4358-8afc-d1cdf35168b3'));
        break;
      }

      case pathname.indexOf('/blackfriday') !== -1 : {
        this.store.dispatch(new GetSpecialVariantFromServer('acbaaf67-7319-47f1-a41d-de5d4a05678a'));
        break;
      }

      case pathname.indexOf('/wow_blackfriday') !== -1 : {
        this.store.dispatch(new GetSpecialVariantFromServer('a9311811-c483-4b0c-bcd2-0935e7ce21e0'));
        break;
      }

      case pathname.indexOf('/better_way') !== -1 : {
        this.store.dispatch(new GetVariantByType(this.storageService.getUserId(), 'better_way'));
        break;
      }

      case pathname.indexOf('/new_way') !== -1 : {
        this.store.dispatch(new GetVariantByType(this.storageService.getUserId(), 'new_way'));
        break;
      }

      case pathname.indexOf('/educationalappstore') !== -1 : {
        this.store.dispatch(new GetSpecialVariantFromServer('ae238d5f-d20e-44d9-922d-272acc781f44'));
        break;
      }

      case pathname.indexOf('/newplans_trial') !== -1 : {
        this.store.dispatch(new GetSpecialVariantFromServer('536f30b6-1a68-4f21-b2ef-fcf1cfd78e64'));
        break;
      }

      case pathname.indexOf('/funandeasy') !== -1 : {
        this.store.dispatch(new GetVariantByType(this.storageService.getUserId(), 'funandeasy'));
        break;
      }

      case pathname.indexOf('/tiktok') !== -1 : {
        this.store.dispatch(new GetVariantByType(this.storageService.getUserId(), 'tiktok'));
        break;
      }

      case pathname.indexOf('/englishforkids') !== -1 : {
        this.store.dispatch(new GetVariantByType(this.storageService.getUserId(), 'englishforkids'));
        break;
      }

      case pathname.indexOf('/newplans-program-1') !== -1 : {
        this.store.dispatch(new GetVariantByType(this.storageService.getUserId(), 'newplans_program_1'));
        break;
      }

      case pathname.indexOf('/sub_cancelled') !== -1 : {
        this.store.dispatch(new GetSpecialVariantFromServer('a37e1978-9a77-4ee5-b607-0623040ce3f3'));
        break;
      }

      case pathname.indexOf('/supspecialoffer') !== -1 : {
        this.store.dispatch(new GetSpecialVariantFromServer('d18ed3b2-a3d3-4f3f-aad0-4986c2dc48c0'));
        break;
      }

      case pathname.indexOf('/flow_us_11') !== -1 : {
        this.store.dispatch(new GetVariantByType(this.storageService.getUserId(), 'flow_us_11'));
        break;
      }

      case pathname.indexOf('/flow_fr') !== -1 : {
        this.store.dispatch(new GetVariantByType(this.storageService.getUserId(), 'flow_fr'));
        break;
      }

      case pathname.indexOf('/flow_it') !== -1 : {
        this.store.dispatch(new GetVariantByType(this.storageService.getUserId(), 'flow_it'));
        break;
      }

      case pathname.indexOf('/flow_us_10') !== -1 : {
        this.store.dispatch(new GetVariantByType(this.storageService.getUserId(), 'flow_us_10'));
        break;
      }

      case pathname.indexOf('/flow_us_21') !== -1 : {
        this.store.dispatch(new GetVariantByType(this.storageService.getUserId(), 'flow_us_21'));
        break;
      }

      case pathname.indexOf('/flow_us_3') !== -1 : {
        this.store.dispatch(new GetVariantByType(this.storageService.getUserId(), 'flow_us_3'));
        break;
      }

      case pathname.indexOf('/flow_ca') !== -1 : {
        this.store.dispatch(new GetVariantByType(this.storageService.getUserId(), 'flow_ca'));
        break;
      }

      case pathname.indexOf('/flow_ww') !== -1 : {
        this.store.dispatch(new GetVariantByType(this.storageService.getUserId(), 'flow_ww'));
        break;
      }

      case pathname.indexOf('/program_1') !== -1 : {
        this.store.dispatch(new GetVariantByBrowserId(this.storageService.getUserId()));
        break;
      }

      case pathname.indexOf('/flow_001') !== -1 : {
        this.store.dispatch(new GetVariantByType(this.storageService.getUserId(), 'flow_001'));
        break;
      }

      case pathname.indexOf('/flow_002') !== -1 : {
        this.store.dispatch(new GetVariantByType(this.storageService.getUserId(), 'flow_002'));
        break;
      }

      case pathname.indexOf('/flow_de_001') !== -1 : {
        this.store.dispatch(new GetVariantByType(this.storageService.getUserId(), 'flow_de_001'));
        break;
      }

      case pathname.indexOf('/flow_br_001') !== -1 : {
        this.store.dispatch(new GetVariantByType(this.storageService.getUserId(), 'flow_br_001'));
        break;
      }

      case pathname.indexOf('/flow_br_002') !== -1 : {
        this.store.dispatch(new GetVariantByType(this.storageService.getUserId(), 'flow_br_002'));
        break;
      }

      case pathname.indexOf('/flow_es') !== -1 : {
        this.store.dispatch(new GetVariantByType(this.storageService.getUserId(), 'flow_es'));
        break;
      }

      case pathname.indexOf('/pay') !== -1 : {
        if (!this.storageService.userIdExist()) {
          this.store.dispatch(new GetSpecialVariantFromServer('cb0eb3d9-ab49-4d69-8d34-0afd10115b7f'));
          return;
        }
        this.store.dispatch(new GetVariantByBrowserId(this.storageService.getUserId()));
        break;
      }

      default : {
        this.store.dispatch(new GetSpecialVariantFromServer('4dcc5e2b-83fb-4699-beb9-e8a045fa2af0'));
      }
    }
  }

  // public insertHotjar(): void {
  //   const node = document.createElement('script');
  //   node.innerHTML = window.location.href.indexOf('education4kids.online') !== -1 ? this.hotjarForEducation() : this.hotjarForWelcome();
  //   document.getElementsByTagName('head')[0].appendChild(node);
  // }

  public updateTag(): void {
    if (window.location.href.indexOf('welcome.intellectokids') !== -1) {
      this.meta.updateTag({ name: 'facebook-domain-verification', content: 'v6ij2s4fju326c0dnde358tz9yhzud' });
    }
  }

  public authByEmail(): void {
    const email = new URLSearchParams(window.location.search).get('email');
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email && email.match(regex)) {
      this.authService
        .signUp(email)
        .subscribe(() => {
          this.store.dispatch(new GetUserInfoUpgrade(false));
          this.getSubscriptions();
          this.store.dispatch(
            new GetUserInfoEmail(email),
          );
          this.analyticEvent();
        });
    }
  }

  public checkCookie(): void {
    if (this.getCookie('visitCounter')) {
      document.cookie = `visitCounter=${ +this.getCookie('visitCounter') + 1 }`;
      return;
    }
    document.cookie = 'visitCounter=1';
  }

  public insertFonts(): void {
    const node = document.createElement('style');
    node.innerHTML = FONTS;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  private analyticEvent(): void {
    this.analyticService
      .analyticWebPoint(AnalyticTypesEnum.WebEmailOpened, {})
      .subscribe();
  }

  private getCookie(name: string): string {
    const matches = document.cookie.match(new RegExp(`(?:^|; )${ name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') }=([^;]*)`));
    return matches ? decodeURIComponent(matches[1]) : '';
  }

  private getSubscriptions(): void {
    this.subscriptionService
      .getAllSubscriptions()
      .pipe()
      .subscribe();
  }

  // private hotjarForWelcome(): string {
  //   return `
  //   (function(h,o,t,j,a,r){
  //     h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  //     h._hjSettings={hjid:2836380,hjsv:6};
  //     a=o.getElementsByTagName('head')[0];
  //     r=o.createElement('script');r.async=1;
  //     r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  //     a.appendChild(r);
  //   })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
  // }

  // private hotjarForEducation(): string {
  //   return `
  //   (function(h,o,t,j,a,r){
  //     h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  //     h._hjSettings={hjid:2838156,hjsv:6};
  //     a=o.getElementsByTagName('head')[0];
  //     r=o.createElement('script');r.async=1;
  //     r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  //     a.appendChild(r);
  //   })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  //   `;
  // }
}
